// import all images from assets/images directory
import img01 from "../all-images/cars-img/1.jpeg";
import img02 from "../all-images/cars-img/2.jpeg";
import img03 from "../all-images/cars-img/3.jpeg";
import img04 from "../all-images/cars-img/4.jpeg";
import img05 from "../all-images/cars-img/5.jpeg";

const carData = [
  {
    imgUrl: img01
  },
    {
        imgUrl: img02
    },
    {
        imgUrl: img03
    },
    {
        imgUrl: img04
    },
    {
        imgUrl: img05
    },
];

export default carData;

// import images from all-images/blog-img directory
import img01 from "../all-images/cars-img/1.jpeg";
import img02 from "../all-images/cars-img/2.jpeg";
import img03 from "../all-images/cars-img/3.jpeg";
import img04 from "../all-images/blog-img/egr.webp";
import img05 from "../all-images/blog-img/particul.webp";
import img06 from "../all-images/blog-img/adblue.webp";
import img07 from "../all-images/blog-img/img07.jpg";

const blogData = [
  {
    id: 1,
    title: "Stage 1 Chip Tuning",
    price: 2500,
    imgUrl: img01,
    description:"Stock araçlar için uygulanır hem performans hemde yakıt tasarrufu sağlanmaktadır.  Fiyat Marka ve Modele göre değişmektedir.",
    quote: "Fiyatlar marka ve modele göre değişmektedir.",
  },

  {
    id: 2,
    title: "Stage 2 Chip Tuning",
    price: 3000,
    imgUrl: img02,
    description: "Genel olarak Performansa yönelik yazılımlar olup ekstra mekaniksel işlemlere ihtiyaç duyar. Fiyat Marka ve Modele göre değişmektedir.",
    quote: "Fiyatlar marka ve modele göre değişmektedir.",
  },

  {
    id: 3,
    title: "Stage 3 Custom Yazılım",
    price: 'Teklif Alın',
    imgUrl: img03,
    description: "Mekaniksel olarak güçlendirilmiş ve alt yapısı tamamlanmış performans araçları için uygulanır.  Fiyat Marka ve Modele göre değişmektedir.",
    quote: "Fiyatlar marka ve modele göre değişmektedir.",
  },


  {
    id: 4,
    title: "Partikül Arıza Çözümü",
    price: 3000,
    imgUrl: img05,
    description: "DPF (Dizel Partikül Filtresi) Arızalarınız Yazılımsal Müdahale ile Araçtan İptal edilerek içi boşaltılır. Sorunsuzluk garantisi verilir. Fiyat Marka ve Modele göre değişmektedir.",
    quote: "Fiyatlar marka ve modele göre değişmektedir.",
  },

  {
    id: 5,
    title: "EGR Arıza Çözümü",
    price: 1500,
    imgUrl: img04,
    description: "EGR (Egzos Geri Dönüşüm Valfi) Arızalarınız Yazılımsal Müdahale ile Araçtan İptal edilerek boğaz kapatılır. Sorunsuzluk garantisi verilir. Fiyat Marka ve Modele göre değişmektedir.",
    quote: "Fiyatlar marka ve modele göre değişmektedir.",
  },

  {
    id: 6,
    title: "Adblue Arıza Çözümü",
    price: 3000,
    imgUrl: img06,
    description: "Adblue Sistemi ve Adblue sıvıları Arızalarınız Yazılımsal Müdahale ile Araçtan İptal edilerek Sorunsuzluk garantisi verilir. Fiyat Marka ve Modele göre değişmektedir.",
    quote: "Fiyatlar marka ve modele göre değişmektedir.",
  },

  {
    id: 7,
    title: "Yağ Bakımı",
    price: 700,
    imgUrl: img07,
    description: "Aracınızın bakımı için gerekli olan yağ değişimini yapıyoruz.",
    quote: "Fiyatlar marka ve modele göre değişmektedir.",
  },
];

export default blogData;

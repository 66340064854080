import React from "react";

import CommonSection from "../components/UI/CommonSection";
import Helmet from "../components/Helmet/Helmet";
import { Container, Row, Col } from "reactstrap";

import "../styles/about.css";

const About = () => {
  return (
    <Helmet title="Hakkımızda">
      <CommonSection title="Hakkımızda" />

      <section className="about__page-section">
        <Container>
          <Row>
            <Col lg="12" md="12" sm="12">
              <div className="about__page-content">
                <h2 className="section__title">
                  EcuNation Tekirdağ ChipTuning - Gizli Özellik - DPF & EGR Çözüm Merkezi
                </h2>

                <p className="section__description">
                  EcuNation Tekirdağ ChipTuning Merkezi ; profesyonel oto geliştirme ve yazılım şirketi olup konusunda uzman ve deneyimli kadrosuyla siz değerli müşterilerimize hizmet vermektedir. Atmosferik veya aşırı besleme her türlü chiptuning elektronik motor modifiyesini, gizli özellik açma uygulamalarını ve Dizel partikül EGR Adblue gibi arıza çözümlerini aracınıza uygulamaktadır.
                </p>

                <p className="section__description">
                  Sektörün en eski firmalarından biri olarak yazılımları müşterilere sunmadan önce, uzun testlerden geçirir daha sonra uygulamaya koymaktadır. Yazılım uygulanan her araç 3 yıl 100.000 km boyunca orjinale dönme, güncelleme, servis giriş çıkışları gibi durumlarda tekrar yazılıma ücret ödemez, ek olarak elektronik ünite 3 yıl boyunca garanti altına alınır.  Profesyonel ekibi ile verdiği hizmetler hakkında daha fazla bilgi almak için iletişim bilgileri üzerinden iletişime geçebilir ve sizlere vereceği kaliteli, güvenilir ve profesyonel hizmetin tadını çıkartabilirsiniz.
                </p>

                <p className="section__description">
                  Lider ve güvenli marka olmak için en doğru bilgiler ışığında hizmet vermekte, aynı zamanda da sektördeki diğer firmalar ile iyi ilişkiler kurmasına da özen göstermektedir.  Ecunation Tekirdağ firması otomotiv sektöründe faaliyet göstermiş olduğu alanda tüm sürecin gereği olarak kalite yönetim sisteminden yararlanmaktadır. Kalite yönetim sistemi, faaliyetlerinin sürekliliği ve standardının sağlanmasında büyük bir öneme sahiptir. Bu sistemin yürütülmesi ve sürdürülebilirliğinin sağlanmasında çalışanların payı oldukça önemli olduğundan süreçte görevli tüm personelin performansını geliştirmek ve motivasyonunu yükseltmek amacıyla çalışmalar yapılmaktadır.
                </p>

                <div className=" d-flex align-items-center gap-3 mt-4">
                  <span className="fs-4">
                    <i class="ri-phone-line"></i>
                  </span>

                  <div>
                    <h6 className="section__subtitle">Yardıma mı ihtiyacın var?</h6>
                    <h4>+90 543 346 57 49</h4>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default About;

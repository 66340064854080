import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../../styles/about-section.css";
import aboutImg from "../../assets/all-images/cars-img/1.jpeg";

const AboutSection = ({ aboutClass }) => {
  return (
    <section
      className="about__section"
      style={
        aboutClass === "aboutPage"
          ? { marginTop: "0px" }
          : { marginTop: "280px" }
      }
    >
      <Container>
        <Row>
          <Col lg="6" md="6">
            <div className="about__section-content">
              <h4 className="section__subtitle">Aracınızın gerçek gücünü fark edin</h4>
              <h2 className="section__title">Daha Fazla Güç & Daha Yüksek Tork</h2>
              <p className="section__description">
                EcuNation Tekirdağ ile Arabanızın güç rezervlerine erişin. EcuNation ayarı her şeyi en üst düzeye çıkarmakla kalmaz, aynı zamanda motor yönetim sisteminizi tasarım sınırları dahilinde akıllıca optimize eder.
              </p>

              <Row>
                <Col lg="6" md="6">
                  <div className="about__section-item d-flex align-items-center">
                    <p className="section__description d-flex align-items-center gap-2">
                      <i className="ri-checkbox-circle-line"></i> Mekanik Bakım Onarım & Arıza Tespit
                    </p>
                  </div>
                </Col>
                <Col lg="6" md="6">
                    <div className="about__section-item d-flex align-items-center">
                        <p className="section__description d-flex align-items-center gap-2">
                        <i className="ri-checkbox-circle-line"></i> Araç Bakımı ve Yağ Değişimi
                        </p>
                    </div>
                </Col>

                <Col lg="6" md="6">
                    <div className="about__section-item d-flex align-items-center">
                        <p className="section__description d-flex align-items-center gap-2">
                        <i className="ri-checkbox-circle-line"></i> Oto Elektrik Bakım Onarım
                        </p>
                    </div>
                </Col>

                <Col lg="6" md="6">
                    <div className="about__section-item d-flex align-items-center">
                        <p className="section__description d-flex align-items-center gap-2">
                        <i className="ri-checkbox-circle-line"></i> Chip Tuning Araç Yazılımı
                        </p>
                    </div>
                </Col>
              </Row>
            </div>
          </Col>

          <Col lg="6" md="6">
            <div className="about__img" style={{height: '20rem'}}>
              <img src={aboutImg} alt="" className="w-100 h-100" style={{objectPosition: 'top', objectFit: 'cover', borderRadius: 12}} />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutSection;

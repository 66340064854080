import React from "react";

import Slider from "react-slick";
import { Container } from "reactstrap";
import { Link } from "react-router-dom";

import "../../styles/hero-slider.css";

const HeroSlider = () => {
  const settings = {
    fade: true,
    speed: 3000,
    autoplaySpeed: 5000,
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
  };
  return (
    <Slider {...settings} className="hero__slider">
      <div className="slider__item slider__item-01 mt0">
        <Container>
          <div className="slider__content ">
            <h1 className="text-light mb-4">DAHA AZ YAKIT TÜKETİMİ, DAHA YÜKSEK GÜÇ</h1>
            <h5 className="text-light mb-3 w-50">Aracınızın motor işletim sistemini; kullanım tarzınıza ve ülkemizin şartlarına göre yeniden programlayarak, "Daha Güçlü" ve "Daha Ekonomik" bir araç haline getirebiliriz.</h5>
          </div>
        </Container>
      </div>

      <div className="slider__item slider__item-02 mt0">
        <Container>
          <div className="slider__content ">
            <h1 className="text-light mb-4">DPF (PARTİKÜL FİLTRESİ) EGR-ADBLUE ARIZA ÇÖZÜMLERİ</h1>
            <h5 className="text-light mb-3 w-50">Kalıcı, Garantili arıza çözümleriniz için EcuNation doğru tercihiniz olacaktır.</h5>

            <button className="btn reserve__btn mt-4">
              <Link to="/iletisim">İletişim</Link>
            </button>
          </div>
        </Container>
      </div>

      <div className="slider__item slider__item-03 mt0">
        <Container>
          <div className="slider__content ">
            <h1 className="text-light mb-4">ARAÇ BAKIMI VE YAĞ DEĞİŞİMİ</h1>
            <h5 className="text-light mb-3 w-50">Araç bakımı ve yağ değişimi konusunda uzman ekibimizle hizmetinizdeyiz.</h5>

            <button className="btn reserve__btn mt-4">
              <Link to="/iletisim">İletişim</Link>
            </button>
          </div>
        </Container>
      </div>

      <div className="slider__item slider__item-04 mt0">
        <Container>
          <div className="slider__content ">
            <h1 className="text-light mb-4">OTO ELEKTRİK BAKIM ONARIM</h1>
            <h5 className="text-light mb-3 w-50">Araç elektrik aksamı konusunda uzman ekibimizle hizmetinizdeyiz.</h5>

            <button className="btn reserve__btn mt-4">
              <Link to="/iletisim">İletişim</Link>
            </button>
          </div>
        </Container>
      </div>
    </Slider>
  );
};

export default HeroSlider;

import React from "react";

import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import { Link } from "react-router-dom";
import "../../styles/footer.css";
import logo from "../../assets/logo.png";

const quickLinks = [
  {
    path: "/hakkimizda",
    display: "Hakkımızda",
  },

  {
    path: "/musterilerimiz",
    display: "Müşterilerimiz",
  },
  {
    path: "/urunler",
    display: "Fiyat Listesi",
  },

  {
    path: "/iletisim",
    display: "İletişim",
  },
];

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col lg="6" md="6" sm="12">
            <div className="logo footer__logo">
              <h1>
                <Link to="/" className=" d-flex align-items-center gap-2">
                  <img src={logo} alt="logo" height={64} />
                </Link>
              </h1>
            </div>
            <p className="footer__logo-content" style={{fontSize: '0.75rem'}}>
              EcuNation aracınızın motor işletim sistemini; kullanım tarzınıza ve ülkemizin şartlarına göre yeniden programlayarak, "Daha Güçlü" ve "Daha Ekonomik" bir araç haline getirmek için çalışan “Yazılım Geliştirme” firmasıdır. Bunu yaparken aracın sağlıklı çalışması ve uzun ömürlü olması birinci önceliğidir Yapılan işlemin temeli; aracın motor kapasitesi dahilinde olup, üretici Firma tarafından kısıtlanmış güçleri ortaya çıkartmaktır.Eğer siz de aracınızın Daha Güçlü ve Daha Ekonomik olmasını istiyorsanız; yılların verdiği tecrübe, bilgi birikimi ve kalitesiyle EcuNation doğru tercihiniz olacaktır.
            </p>
          </Col>

          <Col lg="3" md="4" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title">Hızlı Erişim</h5>
              <ListGroup>
                {quickLinks.map((item, index) => (
                  <ListGroupItem key={index} className="p-0 mt-3 quick__link">
                    <Link to={item.path}>{item.display}</Link>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </div>
          </Col>

          <Col lg="3" md="4" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title mb-4">Adres</h5>
              <p className="office__info">100. Yıl Sanayi Sitesi, Tamirci Sokak No: 1 - Tekirdağ</p>
              <p className="office__info">Açık Saatler: 09:00 - 19:00</p>
            </div>
          </Col>

          <Col lg="12">
            <div className="footer__bottom">
              <p className="section__description d-flex align-items-center justify-content-center gap-1 pt-4">
                <i class="ri-copyright-line"></i>Copyright {year}, EcuNation Tekirdağ - developed by <Link to="https://inception.com.tr">Inception Studios</Link>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;

import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";

import "../styles/contact.css";

const socialLinks = [
  {
    url: "https://www.instagram.com/ecunationtekirdag59/",
    icon: "ri-instagram-line",
  },
  {
    url: "https://www.youtube.com/okantufek",
    icon: "ri-youtube-line",
  },
  {
    url: "https://www.facebook.com/ecunationchiptuning",
    icon: "ri-facebook-line",
  }
];

const Contact = () => {
  return (
    <Helmet title="İletişim">
      <CommonSection title="İletişim" />
      <section>
        <Container>
          <Row>
            <Col lg="7" md="7">
              <h6 className="fw-bold mb-4">İletişime Geçin</h6>
              <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12049.424206371967!2d27.4872316!3d40.9736837!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b461b54b9781d7%3A0x47edb82fac1f84ce!2sEcunation%20Tekirda%C4%9F%20-%20Nokta%20Garaj!5e0!3m2!1str!2str!4v1705508535774!5m2!1str!2str"
                  width="100%"
                  height="450"
                  style={{border:0}}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"/>
            </Col>

            <Col lg="5" md="5">
              <div className="contact__info">
                <h6 className="fw-bold">Adres</h6>
                <p className="section__description mb-0">
                  Atatürk Mah. Yeni Sanayi 3. Sokak No: 15
                </p>
                <div className=" d-flex align-items-center gap-2">
                  <h6 className="fs-6 mb-0">Telefon:</h6>
                  <p className="section__description mb-0">+90 543 346 57 49</p>
                </div>

                <h6 className="fw-bold mt-4">Bizi Takip Et</h6>

                <div className=" d-flex align-items-center gap-4 mt-3">
                  {socialLinks.map((item, index) => (
                    <Link
                      to={item.url}
                      key={index}
                      className="social__link-icon"
                      target="_blank"
                    >
                      <i class={item.icon}></i>
                    </Link>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default Contact;

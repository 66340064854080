import React from "react";
import { Col } from "reactstrap";
import "../../styles/blog-item.css";
import { Link } from "react-router-dom";
import blogData from "../../assets/data/blogData";

const BlogList = () => {
  return (
    <>
      {blogData.map((item) => (
        <BlogItem item={item} key={item.id} />
      ))}
    </>
  );
};

const BlogItem = ({ item }) => {
  const { imgUrl, title, description, price } = item;

  return (
    <Col lg="4" md="6" sm="6" className="mb-5">
      <div className="blog__item">
        <div className="blog__image">
          <img src={imgUrl} alt="" className="w-100" />
        </div>
        <div className="blog__info p-3">
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <Link to={`/urunler/${title}`} className="blog__title">
              {title}
            </Link>
            <span className="blog__date">{price} ₺</span>
          </div>
          <p className="section__description mt-3">
            {description.length > 200
              ? description.substr(0, 100)
              : description}
          </p>

          <Link to={`/urunler/${title}`} className="read__more">
            Bilgi Al
          </Link>
        </div>
      </div>
    </Col>
  );
};

export default BlogList;

const serviceData = [
  {
    id: 1,
    title: "Geniş Bayi Ağı",
    icon: "ri-map-pin-2-line",
    desc: "Dünya Çapında ve Türkiye'nin bir çok noktasında EcuNation hizmetini sizlerle buluşturuyoruz ve Tekirdağ şubesi olarak hizmet veriyoruz.",
  },

  {
    id: 2,
    title: "7/24 Destek",
    icon: "ri-community-line",
    desc: "Çağrı merkezimizden 7/24 aracınız için en doğru desteği ve yazılım bilgisi alabilirsiniz.",
  },

  {
    id: 3,
    title: "%100 Müşteri Memnuniyeti",
    icon: "ri-roadster-line",
    desc: "EcunNation Yazılımlarının Güvenirliğini gönül rahatlığı ile TEST edebilmeniz için",
  },
];

export default serviceData;

import React from "react";
import Slider from "react-slick";

import "../../styles/testimonial.css";

const Testimonial = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    swipeToSlide: true,
    autoplaySpeed: 2000,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      <div className="testimonial py-4 px-3">
        <p className="section__description">
         İbrahim usta sağolsun, işini çok iyi yaptı. Beni bir de çok güzel ağırladı.
        </p>

        <div className="mt-3 d-flex align-items-center gap-4">
          <div>
            <p className="section__description">Müşteri</p>
          </div>
        </div>
      </div>

      <div className="testimonial py-4 px-3">
        <p className="section__description">
          Aracımın partikül filtre problemi vardı, hızlı bir şekilde çözdüler.
        </p>

        <div className="mt-3 d-flex align-items-center gap-4">
          <div>
            <p className="section__description">Müşteri</p>
          </div>
        </div>
      </div>
      <div className="testimonial py-4 px-3">
        <p className="section__description">
          Motorumu toplattım, çok memnun kaldım. İşini çok iyi yapıyorlar.
        </p>

        <div className="mt-3 d-flex align-items-center gap-4">
          <div>
            <p className="section__description">Müşteri</p>
          </div>
        </div>
      </div>
    </Slider>
  );
};

export default Testimonial;

import React, { useRef } from "react";

import { Container, Row, Col } from "reactstrap";
import { Link, NavLink } from "react-router-dom";
import "../../styles/header.css";
import logo from '../../assets/logo.png';
import logoEcunation from '../../assets/logo-ecunation.png'

const navLinks = [
  {
    path: "/",
    display: "Ana Sayfa",
  },
  {
    path: "/hakkimizda",
    display: "Hakkımızda",
  },
  {
    path: "/musterilerimiz",
    display: "Müşterilerimiz",
  },

  {
    path: "/urunler",
    display: "Fiyat Listesi",
  },
  {
    path: "/iletisim",
    display: "İletişim",
  },
];

const Header = () => {
  const menuRef = useRef(null);

  const toggleMenu = () => menuRef.current.classList.toggle("menu__active");

  return (
    <header className="header">
      {/* ============ header top ============ */}
      <div className="header__top">
        <Container>
          <Row>
            <Col lg="6" md="6" sm="6">
              <div className="header__top__left">
                <span>Yardım mı gerekiyor?</span>
                <a href="tel:+905433465749" className="header__top__phone">
                  <span className="header__top__help">
                    <i class="ri-phone-fill"></i> +90 543 346 57 49
                  </span>
                </a>
              </div>
            </Col>

            <Col lg="6" md="6" sm="6">
              <div className="header__top__right d-flex align-items-center justify-content-end gap-3">
                <Link to="https://www.instagram.com/ecunationtekirdag59/" className=" d-flex align-items-center gap-1">
                  <i class="ri-instagram-line"></i> @ecunationtekirdag59
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* =============== header middle =========== */}
      <div className="header__middle">
        <Container>
          <Row>
            <Col lg="4" md="3" sm="4">
              <div className="logo">
                <h1>
                  <Link to="/" className="d-flex align-items-center justify-content-start gap-2">
                    <img src={logoEcunation} alt="logo" />
                    <img src={logo} alt="logo" height={64} />
                  </Link>
                </h1>
              </div>
            </Col>

            <Col lg="4" md="4" sm="4">
              <div className="header__location d-flex align-items-center gap-2">
                <span>
                  <i class="ri-earth-line"></i>
                </span>
                <div className="header__location-content">
                  <h4>Tekirdağ</h4>
                  <h6>100. Yıl Sanayi Sitesi</h6>
                </div>
              </div>
            </Col>

            <Col lg="4" md="4" sm="4">
              <div className="header__location d-flex align-items-center gap-2">
                <span>
                  <i class="ri-time-line"></i>
                </span>
                <div className="header__location-content">
                  <h4>Pazartesi - Cumartesi</h4>
                  <h6>09:00 - 19:00</h6>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* ========== main navigation =========== */}

      <div className="main__navbar">
        <Container>
          <div className="navigation__wrapper d-flex align-items-center justify-content-between">
            <span className="mobile__menu align-items-center gap-2">
              <i class="ri-menu-line" onClick={toggleMenu}></i>
              <Link to="/">
                <img src={logo} alt="logo" height={48} />
              </Link>
            </span>

            <div className="navigation" ref={menuRef} onClick={toggleMenu}>
              <div className="menu">
                {navLinks.map((item, index) => (
                  <NavLink
                    to={item.path}
                    className={(navClass) =>
                      navClass.isActive ? "nav__active nav__item" : "nav__item"
                    }
                    key={index}
                  >
                    {item.display}
                  </NavLink>
                ))}
              </div>
            </div>
            <button className="header__btn btn ">
              <Link to="https://api.whatsapp.com/send?phone=+905433465749&text=Randevu Almak İstiyorum" target="_blank">
                <i className="ri-whatsapp-line"></i> Online Randevu Al
              </Link>
            </button>
          </div>
        </Container>
      </div>
    </header>
  );
};

export default Header;
